/*#region Navbar */

.primary-header {
  padding: var(--size-400) 0;
  background-color: var(--clr-white);
}

@media (min-width: 50em) {
  .primary-header[data-type="fixed"] {
    position: fixed;
  }
}

.nav {
  --max-width: 1700px;
  --container-padding: 2rem;
  z-index: 100;
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  display: grid;
  align-items: center;
  background-color: var(--clr-white);
}

.nav-logo {
  margin: auto 0;
  max-width: 12rem;
}

.nav-mobile img {
  width: 2rem;
  cursor: pointer;
}

@media (min-width: 30em) {
  .nav {
    grid-template-columns: 1fr 3fr;
  }

  .nav-mobile {
    display: none !important;
  }

  .nav-mobile-links {
    display: none !important;
  }

  .nav-extended-links {
    display: block;
  }
}

@media (max-width: 30em) {
  .nav {
    grid-template-columns: 3fr 1fr;
  }

  .nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .nav-extended-links {
    display: none !important;
  }

  .nav-mobile {
    display: block;
  }
}

.nav-extended-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
}

.nav-mobile-links {
  display: grid;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.nav-link,
.dropdown-menu-header {
  font-weight: var(--fw-bold);
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-black);
  font-size: var(--fs-625);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.5rem 0 0.5rem 2rem;
}

.nav-link:hover {
  color: var(--clr-blue-accent-100);
}

.btn[data-type="nav"] {
  border-radius: 1rem;
}

.btn-icon-text[data-type="nav"] {
  font-size: var(--fs-625);
}

.dropdown-menu,
.nav-link {
  border-top: 1px solid black;
}

.nav-link[data-type="desktop"] {
  border: none !important;
  padding: 0;
}

.dropdown-menu-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0.2rem;
}

.dropdown-menu-header img {
  justify-self: flex-end;
  margin-right: 2rem;
  height: 1.2rem;
  width: 1.6rem;
}

.dropdown-menu-items {
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.dropdown-menu-item {
  text-decoration: none;
  font-size: var(--fs-600);
  color: var(--clr-neutral-black);
}

.nav-modal {
  position: absolute;
  margin-top: 0.3rem;
  display: grid;
  gap: 1rem;
  background-color: var(--clr-white);
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 1.875em 1.875em;
  padding: 1em 1.5em;
  z-index: 101;
}

.nav-modal-link {
  text-decoration: none;
  color: var(--clr-black);
  font-weight: var(--fw-bold);
}

.nav-modal-link:hover {
  color: var(--clr-blue-accent-200);
}

.nav-link-dropdown {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup-overlay {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

/*#endregion Navbar*/

/*#region Hero */

.hr-slider {
  position: relative;
}

.hr-slider-controls {
  position: absolute;
  height: 100%;
  width: 3rem;
  cursor: pointer;
}

.controls-left {
  left: 0;
}

.controls-right {
  right: 0;
}

.hr-slider-dots {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 0;
  margin-bottom: 0.6rem;
  margin-right: 1rem;
}

.hr-slider-dot {
  background-color: var(--clr-white-transparent-2);
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.hr-slider-dot-active {
  background-color: var(--clr-white);
}

.hr-slider-info {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 0.8rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  gap: 0.5rem;
  background-color: var(--clr-white);
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  padding: 0.6em 1em;
  width: 14rem;
}

.hr-btn {
  font-size: var(--fs-400);
  padding: 0.6rem 0 !important;
}

.hr-slider-info-text {
  font-size: var(--fs-600);

  font-weight: var(--fw-bold);
}

/*#endregion Hero*/

/*#region Network */

#das-netzwerkbuero .primary-heading {
  margin-bottom: 2rem;
}

.organization-header {
  color: var(--clr-gray-accent-100);
  font-size: var(--fs-600);
  text-align: center;
  font-weight: var(--fw-bold);
  margin-bottom: 2rem;
}

.employee-card {
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
  border-radius: 1em 1em 0 0;
  background-color: var(--clr-white);
  display: grid;
  width: 86%;
  margin: auto;
  margin-top: 3rem;
}

.employee-card-top {
  height: 8rem;
  position: relative;
  background-position: center;
  background-size: cover;
  border-radius: 1em 1em 0 0;
}

.employee-card-top[data-color="at"] {
  background-color: var(--clr-blue-accent-100);
}

.employee-card-top[data-color="amr"] {
  background-color: var(--clr-ocher);
}

.employee-card-top[data-color="mf"] {
  background-color: var(--clr-green-accent-100);
}

.employee-card-img-wrapper {
  position: absolute;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.2);
  background-color: var(--clr-white);
  padding: 0.2em;
  bottom: -2.5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.employee-card-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.employee-card-img[data-type="employee_1"] {
  background-image: url("../img/employee1.webp");
}

.employee-card-img[data-type="employee_2"] {
  background-image: url("../img/employee2.webp");
}

.employee-card-img[data-type="employee_3"] {
  background-image: url("../img/employee3.webp");
}

.employee-card-bottom {
  display: grid;
  gap: 0.5rem;
  margin-top: 3rem;
  padding: 0rem 1rem 2rem 1rem;
}

.employee-card-heading,
.employee-card-sub-heading {
  text-align: center;
}

.employee-card-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.employee-card-sub-heading {
  font-size: var(--fs-600);
}

.employee-card-contact {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
  margin: auto;
}

.employee-card-contact img {
  height: 2rem;
}

.employee-card-contact p {
  font-weight: var(--fw-bold);
  font-size: var(--fs-400);
}

.employee-card-info {
  font-size: var(--fs-300);
  line-height: 1.8;
  text-align: left;
  padding: 0 0.5rem;
  -webkit-hyphens: auto;
}

.member-wrapper {
  display: grid;
  gap: 1.5rem;
}

.member-info {
  color: var(--clr-gray-accent-100);
  line-height: 2;
  font-size: var(--fs-400);
  text-align: justify;
  -webkit-hyphens: auto;
}

.member-contact-info {
  font-size: var(--fs-400);
  text-align: center;
  color: var(--clr-gray-accent-100);
}

.btn[data-type="member"] {
  width: 40%;
  font-size: var(--fs-500);
  padding: 1rem;
  margin: auto;
}

/*#endregion Network */

/*#region News */

.blog-card-list {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.blog-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.blog-card-img {
  border-radius: 1em;
  height: 8rem;
  width: 8rem;
}

.blog-card-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;
}

.blog-card-content {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 0.2rem;
}

.blog-card-header {
  font-weight: var(--fw-bold);
  font-size: var(--fs-400);
  color: var(--clr-black);
  line-height: 2;
  text-align: left;
}

.blog-card-date {
  font-weight: var(--fw-bold);
  color: var(--clr-gray-accent-100);
  font-size: var(--fs-400);
}

.blog-card-info {
  padding: 0 0.5rem;
}

.blog-card-info p {
  color: var(--clr-black);
  font-size: var(--fs-400);
  text-align: justify;
  hyphens: auto;
  line-height: 2;
}

.blog-card-info .btn {
  margin: auto;
  margin-top: 1rem;
  width: 12rem;
  border-radius: 1rem;
  padding: 0.8rem 0.2rem;
}

/*#endregion News*/

/*#region Projects */

.project-card-list {
  display: grid;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.project-card {
  display: grid;
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
}

.project-card-content {
  display: grid;
  gap: 1rem;
  padding: 2rem;
}

.project-card-header {
  text-align: center;
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  color: var(--clr-blue-accent-100);
}

.project-card-img {
  border-radius: 0.5em 0.5em 0 0;
  padding: 0.2em 0.2em 0 0.2em;
}

.btn[data-type="project"] {
  border-radius: 0.5em;
  font-size: var(--fs-400);
  font-weight: var(--fw-semi-bold);
  width: 80%;
  margin: auto;
}

.project-heading {
  text-align: left;
  font-size: var(--fs-650);
  color: var(--clr-blue-accent-100);
  font-weight: var(--fw-bold);
  margin-bottom: 2rem;
}

.project-heading[data-type="cm"] {
  font-size: var(--fs-625);
}

.project-sub-heading {
  text-align: left;
  font-size: var(--fs-600);
  color: var(--clr-neutral-black);
  font-weight: var(--fw-bold);
  margin-bottom: 0.5rem;
}

.project-paragraph {
  margin-top: 1rem;
  text-align: justify;
  hyphens: auto;
  line-height: 2;
  font-size: var(--fs-500);
}

.project-container {
  margin-bottom: 5rem;
}

.project-container .btn {
  width: 20rem;
}

.project-text {
  text-align: justify;
  hyphens: auto;
  line-height: 2;
  font-size: var(--fs-500);
  hyphens: auto;
}

.project-list {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  display: grid;
  gap: 1rem;
  line-height: 2;
  font-size: var(--fs-500);
}

.project-list-item {
  font-size: var(--fs-500);
}

.project-paragraph-img {
  margin-top: 1rem;
  border-radius: 1em;
}

.project-wrapper {
  min-height: 65vh;
}

.project-banner {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.project-banner-img {
  width: 100%;
}

.project-banner-text {
  font-size: var(--fs-400);
  margin-top: -1rem;
}

@media (max-width: 30em) {
  .even-areas[data-type="fp"] {
    margin-bottom: 1rem;
  }

  .project-banner {
    gap: 2rem;
  }

  .project-container .btn {
    margin: auto;
  }
}

@media (min-width: 30em) and (max-width: 114em) {
  .even-areas[data-type="fp"] {
    margin-bottom: 2rem;
  }
}

/*#endregion Projects*/

/*#region Footer */

.primary-footer {
  background-color: var(--clr-blue-accent-100);
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  font-size: var(--fs-400);
  padding: 2rem 0;
}

.primary-footer-links {
  display: flex;
  gap: 1.4rem;
  justify-content: center;
}

.footer-logo-links {
  height: 3rem;
  width: 3rem;
  background-color: var(--clr-blue-accent-200-trans);
  border-radius: 50%;
  padding: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-logo-links:hover {
  opacity: 0.6;
}

.footer-logo-links img {
  height: 2rem;
  margin: auto;
}

.footer-copyright {
  font-weight: var(--fw-semi-bold);
  color: var(--clr-white);
  text-align: center;
}

@media (max-width: 50rem) {
  .footer-copyright {
    width: 32ch !important;
  }
}

.footer-nav {
  display: flex;
  gap: 2rem;
}

.footer-nav a {
  text-decoration: none;
  color: var(--clr-white);
}

.footer-nav a:hover {
  opacity: 0.7;
}

/*#endregion Footer*/

/*#region Contact */

.contact-info {
  margin: 2rem 0 3rem 0;
  display: grid;
  gap: 2rem;
}

.contact-info-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.contact-info-list-item {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.contact-info-list-item img {
  height: 6rem;
  width: 6rem;
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
}

.contact-info-details {
  margin-top: 2rem;
  text-align: left;
}

.contact-form-wrapper {
  margin-top: 2rem;
}

.contact-form-wrapper form {
  display: grid;
  gap: 1rem;
}

.contact-form-confirmation {
  margin: 2rem 0;
}

/*#endregion Contact*/

/*#region Utility classes */

.primary-heading {
  font-size: var(--fs-primary-heading);
  font-weight: var(--fw-bold);
  color: var(--clr-blue-accent-100);
  text-align: center;
}

.primary-heading[data-type="inverted"] {
  color: var(--clr-neutral-white);
}

.secondary-heading {
  font-size: var(--fs-secondary-heading);
  font-weight: var(--fw-bold);
  color: var(--clr-accent-light-green);
}

.tertiary-heading {
  font-size: var(--fs-tertiary-heading);
}

.misc-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-accent-100);
}

.misc-paragraph {
  font-size: var(--fs-300);
  hyphens: auto;
}

.misc-paragraph li {
  margin-left: 3rem;
}

.even-areas {
  display: grid;
}

.even-areas-gap {
  display: grid;
  margin-top: 1rem;
  gap: 1rem;
}

.text-left {
  text-align: start;
}

.paragraph-wrapper {
  display: grid;
  gap: 1rem;
}

.img-round-corners {
  border-radius: 1em;
}

.paragraph {
  text-align: justify;
  max-width: 40ch;
  -webkit-hyphens: auto;
}

.paragraph[data-type="margin-top"] {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}

.text-center p {
  margin-inline: auto;
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.padding-block-900 {
  padding: var(--size-900) 0;
}

.padding-block-700 {
  padding: var(--size-700) 0;
}

.padding-block-500 {
  padding: var(--size-500) 0;
}

.margin-block-500 {
  margin: var(--size-500) 0;
}

.container {
  padding: 0 var(--size-500);
  background-color: var(--clr-white);
}

.container[data-color="gray"] {
  background-color: var(--clr-light-gray);
}

.container[data-color="green"] {
  background-color: var(--clr-green-accent-100);
}

.container[data-color="ocher"] {
  background-color: var(--clr-ocher);
}

.padding-inline {
  padding-right: var(--size-500);
  padding-left: var(--size-500);
}

.horizontal-line {
  border-bottom: 2px solid var(--clr-blue-accent-100);
  width: 90%;
  margin: auto;
}

.card-slider {
  position: relative;
  padding-bottom: 3rem;
}

.card {
  padding: 2rem 1rem;
  background-color: var(--clr-white);
  box-shadow: 0 0.2em 1em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5em 0.5em 0 0;
}

.card-link {
  cursor: pointer;
}

.card-slider-pagination {
  position: absolute;
  display: flex;
  gap: 1rem;
  bottom: 0;
  z-index: 98;
  left: 0;
  right: 0;
  justify-content: center;
}

.card-slider-pagination-item {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--clr-green-accent-200);
  cursor: pointer;
}

.card-slider-pagination-item[data-type="active"] {
  background-color: var(--clr-green-accent-100);
}

.card-slider-pagination-item-active {
  background-color: var(--clr-green-accent-100);
}

.divider-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  line-height: 2;
  font-size: var(--fs-600);
  color: var(--clr-black);
  margin: auto;
}

.divider-text[data-type="inverted"] {
  color: var(--clr-white);
}

.divier-text-small {
  font-size: var(--fs-400);
}

.link {
  color: var(--clr-link);
  text-decoration: none;
  cursor: pointer;
}

.form-control {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.3rem;
}

.form-control label {
  font-size: var(--fs-600);
  color: var(--clr-blue-accent-100);
  font-weight: var(--fw-bold);
}

.form-control input {
  padding: 0 0.3rem;
}

.form-control textarea {
  height: 16rem;
  border-radius: 0.5em;
  padding: 0.5rem;
}

.form-control-info {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.form-control-info input {
  height: 2rem;
  width: 2rem;
}

.form-control-info span {
  font-size: var(--fs-300);
}

/*#endregion Utility classes */

/*#region Media Queries */

/* Large <1248px */
@media (min-width: 87.5em) {
  /* Nav */
  .nav {
    --container-padding: 3rem;
  }

  .primary-header {
    z-index: 999;
    margin: auto;
    width: 100%;
    background-color: var(--clr-white);
  }

  /* Hero */

  .hr-slider-info {
    margin-bottom: 6%;
    margin-left: 8%;
    width: 32rem;
    padding: 1em 2em;
    text-align: left;
    height: 10rem;
  }

  .hr-slider-dots {
    margin-bottom: 2%;
    margin-right: 2%;
    gap: 2rem;
    z-index: 100;
  }

  .hr-slider-dot {
    height: 2rem;
    width: 2rem;
  }

  .hr-slider-info-text {
    font-size: var(--fs-750);
  }

  /* Network */
  #das-netzwerkbuero .even-areas-gap span {
    padding-top: 2rem;
  }

  .employee-card-list {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    margin-top: 2rem;
  }

  .divider-text {
    font-size: var(--fs-625);
  }

  .member-info {
    font-size: var(--fs-600);
  }

  .member-contact-info {
    font-size: var(--fs-600);
  }

  /* News */

  .blog-card {
    width: 60%;
    margin: auto;
  }

  .blog-card-content-wrapper {
    grid-template-columns: 1fr 2fr;
  }

  .blog-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .blog-card-header {
    font-size: var(--fs-650);
  }

  .blog-card-date {
    font-size: var(--fs-600);
  }

  .blog-card-img {
    margin: auto;
    height: 10rem;
    width: 10rem;
  }

  .horizontal-line {
    width: 70%;
  }

  .employee-card {
    height: 40rem;
    grid-template-rows: 1fr 3fr;
    align-items: flex-start;
  }

  .employee-card-bottom {
    margin-top: 1.5rem;
  }

  /* Projects */

  .project-card-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .project-heading {
    font-size: var(--fs-700);
  }

  .project-paragraph,
  .project-text,
  .project-list-item {
    font-size: var(--fs-600);
  }

  .project-wrapper {
    min-height: 78.5vh;
  }

  /* Footer */

  .primary-footer {
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    gap: 4rem;
  }

  .footer-copyright {
    order: 1;
    justify-self: flex-end;
  }

  .primary-footer-links {
    order: 2;
    justify-self: flex-start;
  }

  .primary-footer-nav {
    justify-self: center;
    order: 3;
  }

  /* Contact */
  .contact-info-img-list {
    width: 50%;
    margin: auto;
  }

  .contact-info-img-wrapper {
    display: flex;
    justify-content: center;
  }

  .form-control-info input {
    width: 1rem;
  }

  .form-control-info {
    align-items: center;
  }

  .contact-form-wrapper form .btn {
    width: 40%;
    margin: auto;
  }

  /* General Styling */

  p {
    font-size: var(--fs-600);
    line-height: 2.8;
  }

  .btn {
    padding: 1em 2em;
  }

  .container {
    padding: 0 10%;
  }

  .paragraph {
    max-width: 100%;
  }

  .card {
    width: 36rem;
    margin: auto;
  }

  .card-link {
    cursor: default;
  }

  .card img {
    margin: auto;
    cursor: pointer;
  }

  .even-areas-gap {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  .even-areas {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .paragraph-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

/* Computer 1024px-1248px */
@media (min-width: 66em) and (max-width: 87em) {
  /* Nav */
  .nav {
    --container-padding: 3rem;
  }

  .nav-logo {
    max-width: 10rem;
  }

  .btn-icon-text[data-type="nav"] {
    font-size: var(--fs-600);
  }

  .nav-link {
    font-size: var(--fs-600);
  }

  .nav-modal-link {
    font-size: var(--fs-400);
  }

  .btn[data-type="nav"] {
    font-size: var(--fs-500);
  }

  .primary-header {
    z-index: 999;
    margin: auto;
    width: 100%;
    background-color: var(--clr-white);
  }

  /* Hero */

  .hr-slider-info {
    margin-bottom: 6%;
    margin-left: 8%;
    width: 28rem;
    padding: 1em 2em;
    text-align: left;
    height: 8rem;
  }

  .hr-slider-dots {
    margin-bottom: 2%;
    margin-right: 2%;
    gap: 1rem;
    z-index: 100;
  }

  .hr-slider-dot {
    height: 1.5rem;
    width: 1.5rem;
  }

  .hr-slider-info-text {
    font-size: var(--fs-700);
  }

  /* Network */
  #das-netzwerkbuero .even-areas-gap span {
    padding-top: 2rem;
  }

  .employee-card-list {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    margin-top: 2rem;
  }

  .employee-card-heading {
    font-size: var(--fs-625);
  }

  .employee-card-bottom {
    padding: 0rem 1rem 2rem 1rem;
  }

  .employee-card-sub-heading {
    font-size: var(--fs-400);
  }

  .employee-card-contact p {
    font-size: var(--fs-300);
  }

  .employee-card-contact img {
    height: 1.5rem;
  }

  .divider-text {
    font-size: var(--fs-625);
  }

  .member-info {
    font-size: var(--fs-600);
  }

  .member-contact-info {
    font-size: var(--fs-600);
  }

  /* News */

  .blog-card {
    width: 60%;
    margin: auto;
    gap: 0.5rem;
  }

  .blog-card-content-wrapper {
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }

  .blog-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .blog-card-header {
    font-size: var(--fs-600);
  }

  .blog-card-date {
    font-size: var(--fs-600);
  }

  .blog-card-img {
    margin: auto;
    height: 10rem;
    width: 10rem;
  }

  .horizontal-line {
    width: 70%;
  }

  /* Projects */

  .project-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .project-card-content {
    padding: 1rem;
  }

  .project-card-header {
    font-size: var(--fs-600);
  }

  .btn[data-type="project"] {
    width: 100%;
  }

  .project-heading {
    font-size: var(--fs-700);
  }

  .project-paragraph,
  .project-text,
  .project-list-item {
    font-size: var(--fs-600);
  }

  .project-wrapper {
    min-height: 78.5vh;
  }

  /* Footer */

  footer {
    height: 100%;
  }

  .primary-footer {
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .footer-copyright {
    order: 1;
    justify-self: center;
  }

  .primary-footer-links {
    order: 2;
    justify-self: center;
  }

  .primary-footer-nav {
    justify-self: center;
    order: 3;
  }

  /* Contact */
  .contact-info-img-list {
    width: 50%;
    margin: auto;
  }

  .contact-info-img-wrapper {
    display: flex;
    justify-content: center;
  }

  .form-control-info input {
    width: 1rem;
  }

  .form-control-info {
    align-items: center;
  }

  .contact-form-wrapper form .btn {
    width: 40%;
    margin: auto;
  }

  /* General Styling */

  p {
    font-size: var(--fs-600);
    line-height: 2.8;
  }

  .btn {
    padding: 1em 2em;
  }

  .container {
    padding: 0 10%;
  }

  .paragraph {
    max-width: 100%;
  }

  .card {
    width: 26rem;
    margin: auto;
  }

  .card-link {
    cursor: default;
  }

  .card img {
    margin: auto;
    cursor: pointer;
  }

  .even-areas-gap {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  .even-areas {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .paragraph-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

/* Laptops 864px-1024px */
@media (min-width: 50em) and (max-width: 66em) {
  /* Nav */
  .nav {
    --container-padding: 3rem;
  }

  .nav-logo {
    max-width: 10rem;
  }

  .btn-icon-text[data-type="nav"] {
    font-size: var(--fs-400);
  }

  .nav-link {
    font-size: var(--fs-500);
  }

  .nav-modal-link {
    font-size: var(--fs-400);
  }

  .btn[data-type="nav"] {
    font-size: var(--fs-400);
  }

  .primary-header {
    z-index: 999;
    margin: auto;
    width: 100%;
    background-color: var(--clr-white);
  }

  .nav-extended-links {
    gap: 1rem;
  }

  /* Hero */

  .hr-slider-info {
    margin-bottom: 6%;
    margin-left: 8%;
    width: 28rem;
    padding: 1em 2em;
    text-align: left;
    height: 8rem;
  }

  .hr-slider-dots {
    margin-bottom: 2%;
    margin-right: 2%;
    gap: 1rem;
    z-index: 100;
  }

  .hr-slider-dot {
    height: 1.5rem;
    width: 1.5rem;
  }

  .hr-slider-info-text {
    font-size: var(--fs-700);
  }

  /* Network */
  #das-netzwerkbuero .even-areas-gap span {
    padding-top: 2rem;
  }

  .employee-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
  }

  .employee-card {
    width: 100%;
  }

  .employee-card-heading {
    font-size: var(--fs-600);
  }

  .employee-card-bottom {
    padding: 0rem 0.4rem 2rem 0.4rem;
  }

  .employee-card-sub-heading {
    font-size: 0.7rem;
  }

  .employee-card-contact p {
    font-size: var(--fs-300);
  }

  .employee-card-contact img {
    height: 1.5rem;
  }

  .divider-text {
    font-size: var(--fs-625);
  }

  .member-info {
    font-size: var(--fs-600);
  }

  .member-contact-info {
    font-size: var(--fs-600);
  }

  /* News */

  .blog-card {
    width: 60%;
    margin: auto;
    gap: 0.5rem;
  }

  .blog-card-content-wrapper {
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }

  .blog-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .blog-card-header {
    font-size: var(--fs-600);
  }

  .blog-card-date {
    font-size: var(--fs-600);
  }

  .blog-card-img {
    margin: auto;
    height: 10rem;
    width: 10rem;
  }

  .horizontal-line {
    width: 70%;
  }

  /* Projects */

  .project-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .project-card-content {
    padding: 1rem;
  }

  .project-card-header {
    font-size: var(--fs-600);
  }

  .btn[data-type="project"] {
    width: 100%;
    font-size: var(--fs-300);
    padding: 1rem 0;
  }

  .project-heading {
    font-size: var(--fs-700);
  }

  .project-paragraph,
  .project-text,
  .project-list-item {
    font-size: var(--fs-600);
  }

  .project-wrapper {
    min-height: 78.5vh;
  }

  /* Footer */

  .primary-footer {
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    gap: 2rem;
    padding: 2rem 3rem;
  }

  .footer-copyright {
    order: 1;
    justify-self: center;
  }

  .primary-footer-links {
    order: 2;
    justify-self: center;
  }

  .primary-footer-nav {
    justify-self: center;
    order: 3;
  }

  /* Contact */
  .contact-info-img-list {
    width: 50%;
    margin: auto;
  }

  .contact-info-img-wrapper {
    display: flex;
    justify-content: center;
  }

  .form-control-info input {
    width: 1rem;
  }

  .form-control-info {
    align-items: center;
  }

  .contact-form-wrapper form .btn {
    width: 40%;
    margin: auto;
  }

  /* General Styling */

  p {
    font-size: var(--fs-600);
    line-height: 2.8;
  }

  .btn {
    padding: 1em 2em;
  }

  .container {
    padding: 0 10%;
  }

  .paragraph {
    max-width: 100%;
  }

  .card {
    width: 22rem;
    margin: auto;
  }

  .card-link {
    cursor: default;
  }

  .card img {
    margin: auto;
    cursor: pointer;
  }

  .even-areas-gap {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
  }

  .even-areas {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .paragraph-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .primary-heading {
    font-size: var(--fs-650);
  }
}

/* Tablet 480px-860px */
@media (min-width: 30em) and (max-width: 50em) {
  /* Nav */
  .nav {
    --container-padding: 3rem;
  }

  .nav-logo {
    max-width: 10rem;
  }

  .btn-icon-text[data-type="nav"] {
    font-size: var(--fs-400);
  }

  .nav-link {
    font-size: var(--fs-500);
  }

  .nav-modal-link {
    font-size: var(--fs-400);
  }

  .btn[data-type="nav"] {
    font-size: var(--fs-400);
  }

  .primary-header {
    z-index: 999;
    margin: auto;
    width: 100%;
    background-color: var(--clr-white);
  }

  .nav-extended-links {
    gap: 1rem;
  }

  /* Hero */

  .hr-slider-info {
    margin-bottom: 6%;
    margin-left: 8%;
    width: 28rem;
    padding: 1em 2em;
    text-align: left;
    height: 8rem;
  }

  .hr-slider-dots {
    margin-bottom: 2%;
    margin-right: 2%;
    gap: 1rem;
    z-index: 100;
  }

  .hr-slider-dot {
    height: 1.5rem;
    width: 1.5rem;
  }

  .hr-slider-info-text {
    font-size: var(--fs-700);
  }

  /* Network */
  #das-netzwerkbuero .even-areas-gap span {
    padding-top: 2rem;
  }

  .employee-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
  }

  .employee-card {
    width: 100%;
  }

  .employee-card-heading {
    font-size: var(--fs-600);
  }

  .employee-card-bottom {
    padding: 0rem 0.4rem 2rem 0.4rem;
  }

  .employee-card-sub-heading {
    font-size: 0.7rem;
  }

  .employee-card-contact p {
    font-size: var(--fs-300);
  }

  .employee-card-contact img {
    height: 1.5rem;
  }

  .divider-text {
    font-size: var(--fs-625);
  }

  .member-info {
    font-size: var(--fs-600);
  }

  .member-contact-info {
    font-size: var(--fs-600);
  }

  /* News */

  .blog-card {
    width: 60%;
    margin: auto;
    gap: 0.5rem;
  }

  .blog-card-content-wrapper {
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }

  .blog-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .blog-card-header {
    font-size: var(--fs-600);
  }

  .blog-card-date {
    font-size: var(--fs-600);
  }

  .blog-card-img {
    margin: auto;
    height: 10rem;
    width: 10rem;
  }

  .horizontal-line {
    width: 70%;
  }

  /* Projects */

  .project-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .project-card-content {
    padding: 1rem;
  }

  .project-card-header {
    font-size: var(--fs-600);
  }

  .btn[data-type="project"] {
    width: 100%;
    font-size: var(--fs-300);
    padding: 1rem 0;
  }

  .project-heading {
    font-size: var(--fs-700);
  }

  .project-paragraph,
  .project-text,
  .project-list-item {
    font-size: var(--fs-600);
  }

  .project-wrapper {
    min-height: 78.5vh;
  }

  /* Footer */

  .primary-footer {
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
    gap: 2rem;
    padding: 2rem 3rem;
  }

  .footer-copyright {
    order: 1;
    justify-self: center;
  }

  .primary-footer-links {
    order: 2;
    justify-self: center;
  }

  .primary-footer-nav {
    justify-self: center;
    order: 3;
  }

  /* Contact */
  .contact-info-img-list {
    width: 50%;
    margin: auto;
  }

  .contact-info-img-wrapper {
    display: flex;
    justify-content: center;
  }

  .form-control-info input {
    width: 1rem;
  }

  .form-control-info {
    align-items: center;
  }

  .contact-form-wrapper form .btn {
    width: 40%;
    margin: auto;
  }

  /* General Styling */

  p {
    font-size: var(--fs-600);
    line-height: 2.8;
  }

  .btn {
    padding: 1em 2em;
  }

  .container {
    padding: 0 10%;
  }

  .paragraph {
    max-width: 100%;
  }

  .card {
    width: 22rem;
    margin: auto;
  }

  .card-link {
    cursor: default;
  }

  .card img {
    margin: auto;
    cursor: pointer;
  }

  .even-areas-gap {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
  }

  .even-areas {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .paragraph-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .primary-heading {
    font-size: var(--fs-650);
  }
}

@media (min-width: 40em) {
  .even-areas[data-type="cm"] {
    grid-template-columns: 0.75fr 1.25fr;
  }

  .even-areas[data-type="cm2"],
  .even-areas[data-type="fp"] {
    grid-template-columns: 1.2fr auto;
    align-items: flex-start;
  }

  .project-paragraph-img[data-type="cm2"] {
    width: 34rem;
    margin-top: 0;
  }

  .project-list {
    margin-top: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 120em) {
  .project-list {
    margin-top: 0;
    padding-left: 2rem;
  }
}

/*#endregion Media Queries*/
