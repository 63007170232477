@font-face {
  font-family: "be_vietnam_proregular";
  src: url("./fonts/bevietnampro/bevietnampro-regular-webfont.woff2")
      format("woff2"),
    url("./fonts/bevietnampro/bevietnampro-regular-webfont.woff") format("woff");
  font-weight: normal;
}

/*#region Root Variables */

:root {
  /* Main Colors */
  --clr-blue-accent-100: hsl(217 33% 35%);
  --clr-blue-accent-100-trans: hsla(218, 33%, 35%, 0.726);
  --clr-blue-accent-200: hsl(217 33% 60%);
  --clr-blue-accent-200-trans: hsla(217, 33%, 60%, 0.206);
  --clr-green-accent-100: hsl(135 26% 58%);
  --clr-green-accent-200: hsla(136, 26%, 58%, 0.389);
  --clr-ocher: hsl(44 45% 62%);

  /* Shades */
  --clr-white: hsl(0 0% 100%);
  --clr-white-transparent-1: hsla(0, 0%, 100%, 0.032);
  --clr-white-transparent-2: hsla(0, 0%, 100%, 0.75);
  --clr-light-gray: hsl(0 0% 98%);
  --clr-gray-accent-100: hsl(0 0% 44%);
  --clr-gray-accent-200: hsl(0 0% 52%);
  --clr-black: hsl(0, 0%, 0%);

  /* Link colors */
  --clr-link: hsl(217, 93%, 35%);

  /* Font Style */
  --ff-body: "be_vietnam_proregular", sans-serif;

  /* Font Weights */
  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  /* Font Sizes */
  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-625: 1.25rem;
  --fs-650: 1.5rem;
  --fs-700: 1.875rem;
  --fs-750: 2.25rem;
  --fs-800: 2.5rem;
  --fs-850: 3rem;
  --fs-900: 3.5rem;
  --fs-1000: 5rem;

  /* Sizes */
  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;

  /* Standard Element Font Styles */
  --fs-body: var(--fs-500);
  --fs-primary-heading: var(--fs-700);
  --fs-secondary-heading: var(--fs-800);
  --fs-tertiary-heading: var(--fs-650);
  --fs-citactions: var(--fs-600);
  --fs-nav: var(--fs-500);
  --fs-btn: var(--fs-600);
  --fs-btn-icon: var(--fs-600);
}

/*#endregion Root Variables */

/*#region General Site Styling */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  background-color: var(--clr-blue-accent-100);
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*#endregion General Site Styling  */

/*#region General Styling */

body {
  font-family: var(--ff-body);
  color: var(--clr-primary-400);
}

main {
  background-color: var(--clr-white);
}

p {
  font-size: var(--fs-body);
  max-width: 32;
  line-height: 2;
}

/*#region Buttons */

.btn {
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 100vmax;
  padding: 1.25em 2.5em;
  font-weight: var(--fw-bold);
  font-size: var(--fs-btn);
  line-height: 1;
  color: var(--clr-white);
  background-color: var(--clr-blue-accent-100);
  box-shadow: 0 1.125em 1em -1em var(--clr-blue-accent-200);
}

.btn[data-type="inverted"] {
  background-color: var(--clr-white);
  color: var(--clr-blue-accent-200);
}

.btn[data-shadow="none"] {
  box-shadow: none;
}

.btn:hover,
.btn:focus-visible {
  background-color: var(--clr-blue-accent-200);
}

.btn[data-type="inverted"]:hover,
.btn[data-type="inverted"]:focus-visible {
  background-color: var(--clr-white);
  color: var(--clr-blue-accent-200);
}

.btn-icon {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 100vmax;
  padding: 0.5em 0.75em;
  line-height: 1;
  background-color: var(--clr-accent-400);
}

.btn-icon[data-type="inner-btn"] {
  padding: 0;
}

@media (max-width: 30em) {
  .btn-icon {
    width: 10rem;
    margin-top: 0.5rem;
  }
}

.btn-icon-text {
  display: flex;
  align-items: center;
  padding: 1rem auto;
  font-size: var(--fs-btn-icon);
  font-weight: var(--fw-bold);
}

.btn-icon img {
  max-width: 1.5rem;
  height: auto;
}

/*#endregion Buttons*/

/*#endregion General Styling */
